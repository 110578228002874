<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent max-width="550"
      >
      <v-card class="pt-2 px-2 pb-4">
          <div class="d-flex flex-row justify-end">
              <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </div>
          
          <h3 class="poppins primary--text mb-3 mx-2 mt-0">
            {{ type === 'terms_of_use' ? 'Terms of Use' : 'Data Privacy'  }}
          </h3>

          <div v-for="(item, i) in (type === 'terms_of_use' ? terms_of_use : data_privacy)" :key="i" class="pa-2 f12">
            {{ item }}
          </div>
      </v-card>
    </v-dialog>
    <div class="pa-10 d-flex align-center justify-center">
      <div @click="() => { dialog = true, type = 'terms_of_use' }" class="primary--text f12 mx-2 pointer">
        Terms of Use
      </div>
      <div @click="() => { dialog = true, type = 'data_privacy' }" class="primary--text f12 mx-2 pointer">
        Data Privacy
      </div>
    </div>

  </div>
</template>

<script>
import data_privacy from "@/constants/dataprivacy"
import terms_of_use from "@/constants/termsofuse"

export default {
  data: () => ({
    data_privacy,
    terms_of_use,
    type: '',
    dialog: false
  })
}
</script>
